import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Container } from '../components/base';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Hero } from '../layout/hero';
import { Category } from '../layout/category';
import { Marks } from '../layout/marks';
import { Aside } from '../layout/aside';

interface IndexPageProps {
    data: {
        site: {
            siteMetadata: {
                title: string;
            };
        };
    };
}

export default ({ data }: any) => (
    <>
        {console.log('dato in index', data)}

        {/* <HelmetDatoCms seo={data.datoCmsSite.faviconMetaTags}>
            <title>{data.datoCmsSite.globalSeo.fallbackSeo.title}</title>
            <meta name="description" content={data.datoCmsSite.globalSeo.fallbackSeo.description} />
        </HelmetDatoCms> */}

        <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags}>
            <title>{data.datoCmsSite.globalSeo.fallbackSeo.title}</title>
            <meta name="description" content={data.datoCmsSite.globalSeo.fallbackSeo.description} />
        </HelmetDatoCms>

        <Container>
            <Category list={data.allDatoCmsPage.nodes} pageInfo={data.datoCmsPage} />
            <Aside aside={data.datoCmsIndex.aside} asideImg={data.datoCmsIndex.asideImg} />
            {/* <ToHtml tags={data.datoCmsIndex.aside} /> */}
            <Marks data={data.allDatoCmsMark} />
        </Container>
    </>
);

export const indexquery = graphql`
    query indexquery {
        datoCmsIndex {
            content
            img {
                fixed(width: 1670, imgixParams: { fm: "jpg", auto: "compress" }) {
                    ...GatsbyDatoCmsFixed
                }
            }
            title
            aside
            asideImg {
                fixed(width: 862, imgixParams: { fm: "jpg", auto: "compress" }) {
                    ...GatsbyDatoCmsFixed
                }
            }
        }
        datoCmsPage(slug: { eq: "oferta" }) {
            title
            content
            slug
        }
        allDatoCmsPage(filter: { category: { slug: { eq: "oferta" } } }) {
            nodes {
                title
                slug
                category {
                    slug
                }
                img {
                    fixed(width: 1670, imgixParams: { fm: "jpg", auto: "compress" }) {
                        ...GatsbyDatoCmsFixed
                    }
                }
            }
        }
        allDatoCmsMark {
            nodes {
                name
                img {
                    fixed(width: 232, imgixParams: { fm: "jpg", auto: "compress" }) {
                        ...GatsbyDatoCmsFixed
                    }
                    url
                }
            }
        }
        datoCmsSite {
            globalSeo {
                siteName
                titleSuffix
                fallbackSeo {
                    description
                    image {
                        fixed {
                            src
                        }
                    }
                    title
                    twitterCard
                }
            }
            faviconMetaTags {
                tags
            }
        }
    }
`;
