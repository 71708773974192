import React from 'react';
import styled from 'styled-components';
import ToHtml from '../components/html';
import { Gimg } from '../components/gimg';
import mapIcon from '../assets/map-marker-2.svg';
import { colors, mediaDesktop, mediaTablet } from '../components/setting';
import { Link } from 'gatsby';
import { LinkButton } from '../components/button';
import { Icon, IconText } from '../components/base';

type AsideProps = {
	aside: string;
	asideImg: {
		fixed: any;
	};
};

export const Aside = (props: AsideProps) => (
	<Container>
		<Image>
			<Gimg
				fixed={props.asideImg.fixed}
				style={{
					width: '100%',
					height: '100%',
					display: 'block',
				}}
			/>
		</Image>
		<Content>
			<ToHtml tags={props.aside} />
			<Action>
				<LinkButton to="/kontakt/" as={Link}>
					<Icon style={{ top: '-3px' }}>
						<img src={mapIcon} alt="" />
					</Icon>
					<IconText>Pokaż mapę</IconText>
				</LinkButton>
			</Action>
		</Content>
	</Container>
);

const Container = styled.aside`
	display: flex;
	padding: 4rem 2rem;
	margin: 10rem -2rem;

	@media ${mediaTablet} {
		padding: 4rem 6rem;
		margin: 10rem -6rem 10rem;
	}
	@media ${mediaDesktop} {
		padding: 6rem;
		margin: 20rem 0 10rem;
	}
	background: ${colors.gray};
`;

const Image = styled.div`
	display: none;
	@media ${mediaDesktop} {
		display: block;
		width: calc(50% + 12rem);
		margin: -22rem 6rem 0 -12rem;
	}
`;
const Content = styled.div`
	@media ${mediaDesktop} {
		width: 50%;
	}
`;

const Action = styled.div`
	margin: 3rem 0;
`;
